import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ItPark from "../images/it-park.png";
import EducationalInstitute from "../images/education.png";
import Hospital from "../images/hospital.png";
import Entertainment from "../images/entertainment.png";
import ArrowLeft from "../images/arrow-left-dark.svg";
import ArrowRight from "../images/arrow-right-dark.svg";
import '../style/map.css';
// import ItPark from "../../../assets/images/Web/it-park.png";
// import EducationalInstitute from "../../../assets/images/Web/education.png";
// import Hospital from "../../../assets/images/Web/hospital.png";
// import Entertainment from "../../../assets/images/Web/entertainment.png";
// import "./style.css";
// import PrevArrow from "../../../assets/images/prev-arrow.png";

const MapSection = ({ setModalShow }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [ItPark, Entertainment, Hospital, EducationalInstitute];
  const texts = [
    "IT Parks",
    "Entertainment",
    "Hospitals",
    "Educational Institutes",
  ];

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const handleClick = () => {
    setModalShow(true);
  };
  return (
    <Container fluid style={{ height: "100%" }} id="location" className="px-0 px-md-2">
      <Row>
        <Col md={6} className="map-wrapper px-2 pe-0">
          {/* Content for the left half */}
          <div className="border border-3 border-white">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15548.198203047059!2d77.75801288061005!3d13.03251659222931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4
            f13.1!3m3!1m2!1s0x3bae0f0011889539%3A0xe1ae1f145fee3ae8!2sVirtue%20Nirvana!5e0!3m2!1sen!2sin!4v1721652820324!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowfullscreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              style={{
                border: "0px",
                margin: "0px",
                height: "600px",
              }}>
            </iframe>
          </div>
        </Col>
        <Col md={6} className="bg-light">
          {/* Content for the right half */}
          <div style={{ backgroundColor: "#fffff" }}>
            <div
              style={{ marginTop: "30px", marginBottom: "30px" }}
              className="d-flex justify-content-center flex-column align-items-center map-content">
              <div>
                <span className="map-title">Discover The </span>
              </div>
              <div>
                <span className="map-title">Neighbourhood</span>
              </div>
            </div>
            <Container
              fluid
              className="d-flex justify-content-center align-items-center">
              {/* Left arrow */}
              {/* <div className="arrow left" onClick={handlePrevious} /> */}
              <img src={ArrowLeft} className="map-arrow-img cursor-pointer" alt="Arrow Left" onClick={handlePrevious} />
              {/* Image */}
              <div className="image-container-map px-4">
                <img src={images[currentImageIndex]} alt="Map" />
              </div>

              {/* Right arrow */}
              {/* <div className="arrow right" onClick={handleNext} /> */}
              <img src={ArrowRight} className="map-arrow-img cursor-pointer" alt="Arrow Right" onClick={handleNext} />
            </Container>
            <div className="text-span-bg d-flex justify-content-center align-items-center">
              {/* <span>Bagaluru Lake - 3.2Km</span> */}
              <span>{texts[currentImageIndex]}</span>
            </div>
            <div
              style={{ marginTop: "10px" }}
              className="d-flex justify-content-center align-items-center">
              <span className="span-text">{currentImageIndex + 1}</span>
              <hr
                style={{
                  borderWidth: "1px solid #151B54",
                  width: "190px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  border: "1px solid #151B54",
                }}
              />{" "}
              <span className="span-text">4</span>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn-outline-dark"
                style={{ marginTop: "15px" }} onClick={handleClick}>
                Request a call back
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MapSection;
