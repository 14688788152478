import React, { useState, useEffect } from "react";
import Flower from "../images/Group 5.svg";
import DownArrow from "../images/down-arrow.svg";
// import "./style.css";
import { Accordion } from "react-bootstrap";

const cardData = [
    {
        title: "Prime Location in East Bangalore",
        para: "Enjoy the perfect blend of connectivity and tranquility in the prime residential locale of Kannamangala, Whitefield",
        url: Flower,
    },
    {
        title: "Innovative Design and Architecture",
        para: "Contemporary design and high-end finishes set a new standard in elegance.",
        url: Flower,
    },
    {
        title: "Lifestyle Amenities",
        para: "Well planned modern amenities for a lifestyle of comfort and convenience.",
        url: Flower,
    },
    {
        title: "Spacious Living Areas",
        para: "Boasting the highest carpet area in the segment for maximum comfort.",
        url: Flower,
    },
    {
        title: "Highest UDS in Segment",
        para: "Greater ownership with the highest undivided share of land.",
        url: Flower,
    },
    {
        title: "Renowned Global Brands",
        para: "Premium construction and finishes featuring top global brands.",
    },
    {
        title: "No Shared Walls",
        para: "Ensuring ultimate privacy and peace in every home.",
    },
    {
        title: "Vastu Compliant Homes",
        para: "Experience harmony and positive energy in thoughtfully designed spaces.",
    },
    {
        title: "Reputable Brand",
        para: "A track record of excellence in quality construction and timely delivery",
    },
];

const ElevateYourLife = ({ setModalShow }) => {
    const [count, setCount] = useState(0);
    const [{ items }, setItems] = useState({ items: [] });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(() => {
        if (items == "") addItem();
    }, []);

    const addItem = () => {
        var respCount = windowWidth > 768 ? 9 : 4;
        var cardLength = count + respCount;

        if (count == cardData.length) return;

        if (windowWidth > 768) {
            for (var i = count; i < cardLength; i++) {
                items.push(
                    <div
                        key={i}
                        className="col-12 col-md-3 col-lg-4 mb-4 ">
                        {/* <div className="card aminities_card">
                        <img className="col-4" src={Flower} alt="test" />
                        <h5 className="card_h5 text-center pt-4">{cardData[i].title}</h5>
                        <p>{cardData[i].para}</p>
                    </div> */}
                        <div className="d-flex align-items-start">
                            <div className="pe-3">
                                <img className="custom-img bg-transparent" src={Flower} alt="test" style={{ width: "30px", height: "30px" }} />
                            </div>
                            <div>
                                <h3 className="text-gray-700">{cardData[i].title}</h3>
                                <p className="text-gray-700" style={{ fontSize: "13px" }}>{cardData[i].para}</p>
                            </div>
                        </div>
                    </div>
                    // <div className="d-flex align-items-start">
                    //     <div>
                    //         <img className="col-4" src={Flower} alt="test" />
                    //     </div>
                    //     <div>
                    //         <h5 className="card_h5 text-center pt-4">{cardData[i].title}</h5>
                    //         <p>{cardData[i].para}</p>
                    //     </div>
                    // </div>
                );
            }

        }
         else {
            items.push(
                <div className="col-12 col-md-7">
                    <Accordion defaultActiveKey="0">
                        {cardData.map((item, index) => (
                            <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header className="acc-header-custom position-relative bg-light mb-2">
                                    <div className="pe-3">
                                        <img className="custom-img bg-transparent" src={Flower} alt="test" style={{ width: "30px", height: "30px" }} />
                                    </div>
                                    <div className="d-flex align-items-center acc-header-custom">
                                        <h4 className="mb-0">{item.title}</h4>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body
                                    className="text-start accordion-body"
                                    style={{ borderBottom: "1px solid #ccc" }}
                                >
                                    {item.para}
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            )
        }
        setItems({ items: [...items] });
        setCount(cardLength);
    };


    return (
        <div
            id="gallery"
            className="py-4 justify-content-center amenities-wrapper container ">
            {/* <h4 className="container amenities_title text-dark mb-0"> Elevate Your Life</h4>
            <h4 className="container amenities_title text-dark pb-5">at Nirvana</h4> */}
            <h4 className="amenities_title text-dark text-center py-2 py-5 px-3 px-md-0">Elevate Your Living: <br />The Nirvana Advantage </h4>
            <div className="col-12 row text-dark">{items}</div>
        </div>


    );
};

export default ElevateYourLife;
