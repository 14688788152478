import React from "react";
import "./style.css";
const Space = ({ setModalShow }) => {
  const spaceData = [
    {
      id: 1,
      config: "Configuration",
      bhk: "2 BHK",
      size: "Size",
      sq: "1072 - 1130 sqft",
      price: "Get Pricing",
    },
    {
      id: 2,
      config: "Configuration",
      bhk: "3 BHK",
      size: "Size",
      sq: "1537 - 1780 sqft",
      price: "Get Pricing",
    },
  ];
  const handleClick = () => {
    setModalShow(true);
  };
  return (
    <>
      {/* <div id="configuration" className="space-wrapper pt-5">
        <div>
          <span className="space">Your Space, Your</span>{" "}
        </div>
        <div>
          <span className="space"> Legacy, Your Price</span>
        </div>
      </div> */}
      <div id="configuration">
        <h4 className="amenities_title text-center text-dark py-2 py-5">Spacious and Thoughtfully <br />Designed Floor Plans</h4>
        <div className="container2 pb-5">
          {spaceData.map((data) => (
            <>

              <div className="box2 bg-light shadow-sm font-brandon">
                <div className="text2-co font-brandon" style={{ color: "#1f1f1f" }}>{data.config}</div>
                <div className="text2-bhk fw-semibold font-brandon" style={{ color: "#1f1f1f" }}>{data.bhk}</div>
                <div className="text2-sz font-brandon fw-semibold" style={{ color: "#1f1f1f" }}>{data.size}</div>
                <div className="text2-sq fw-semibold font-brandon" style={{ color: "#1f1f1f" }}>{data.sq}</div>
                <div className="pt-4">
                  <button className="btn-border-radius btn-gradient px-5" onClick={handleClick}>
                    {data.price}
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default Space;
