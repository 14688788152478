import React from "react";
import Logo from "../images/brand-logo-horizontal.svg";

const PrivacyPolicy = () => {
  return (
    <div className="d-flex justify-content-center flex-column mx-auto policy">
      <div className="col-11 col-md-10 mx-auto align-items-center justify-content-center">
        <img
          style={{ width: "200px", backgroundColor: "#fff" }}
          className="my-3
         d-flex mx-auto"
          src={Logo}
          alt="logo"
        />
        <h1 className="text-dark my-3">Privacy Policy</h1>
        <h5 style={{ textAlign: "justify" }} className="my-3">
          This policy is in relation to the information/data we collect from you
          when you use our website and the manner in which we hold, process and
          use the data collected.
        </h5>
        <h4 className="my-3">DATA COLLECTION AND USAGE</h4>
        <ul>
          <li>
            We may collect the following data from you: -Name, phone number and
            email address
          </li>
          <li>
            Data secured from communications through mails, messages and calls
          </li>
          <li>
            Other data including but not limited to technical information
            regarding location, IP address and network carrier when you use our
            website
          </li>
          <li>
            When you use our website, you unconditionally give us consent to
            collect, store, retain and process any information provided by you.
            The information is used for the following purposes: -To improve the
            efficiency of our website and to provide a better user experience
          </li>
          <li>
            Effective communication and customer service, appraising you about
            various schemes for purchase of property in any of our projects
          </li>
          <li>
            Proceed with purchase of a unit/plot/site in our project/s and
            execution of agreement of sale and all related purposes
          </li>
          <li>
            The email address provided is used to give you updates about your
            unit/ enquiry/ request as well as occasional company news, updates,
            promotions, related product or service information
          </li>
        </ul>

        <p>
          You here by give consent for Virtue Infra and/or any of its
          affiliates, to disclose the information/data, if required under the
          applicable laws. The information provided to us is not shared or
          communicated to any third parties or other stakeholder/s.
        </p>
        <p>
          This policy is applicable for the information collected through our
          website and not for information collected offline. <br />
          Any changes/ modifications to this policy, shall be updated on this
          page from time to time. We recommend that you review this policy from
          time to time to stay updated with changes/modifications, if any.
        </p>
        <p>
          In the event that you have any further queries, require any further
          information regarding your data in our possession, you can reach to us
          at enquiries hello@aspireprop.com
        </p>
        <p>
          When you voluntarily send us electronic mail, we will keep a record of
          this information so that we can respond to you. We only collect
          information from you when you register on our site or fill out a form.
          Also, when filling out a form on our site, you may be asked to enter
          your: name, e-mail address or phone number. You may, however, visit
          our site anonymously. In case you have submitted your personal
          information and contact details, we reserve the rights to Call, SMS,
          Email or WhatsApp about our products and offers, even if your number
          has DND activated on it.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
