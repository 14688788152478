import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BHK from "../images/SRI NIRVANA VIRTUE INFRA TYPICAL FLOOR PLAN.png"
import TwoBHK from "../images/slider-1.png";
import ThreeBHK from "../images/3BHK.png";

import TwoBHKImg1 from "../images/2bhk/img1.png";
import TwoBHKImg2 from "../images/2bhk/img2.png";
import TwoBHKImg3 from "../images/2bhk/img3.png";



import ThreeBHKImg1 from "../images/3bhk/img1.png";
import ThreeBHKImg2 from "../images/3bhk/img2.png";
import ThreeBHKImg3 from "../images/3bhk/img3.png";
import ThreeBHKImg4 from "../images/3bhk/img4.png";
import ThreeBHKImg5 from "../images/3bhk/img5.png";
import ThreeBHKImg6 from "../images/3bhk/img6.png";

import Site2BHK from "../images/sitePlan2bhk.png";
import ArrowLeft from "../images/arrow-left-dark.svg";
import ArrowRight from "../images/arrow-right-dark.svg";
import '../style/map.css';
import Slider from "react-slick";


const SitePlan = ({ setModalShow }) => {

  const CustomPrevArrow = (props) => (
    <div
      {...props}
      style={{
        ...props.style,
        left: "20px",
        zIndex: 1,
        borderRadius: "50%",
        cursor: "pointer",
      }}
    >
      <img style={{ maxWidth: "45px" }} src={ArrowLeft} alt="" />
    </div>
  );

  // Custom Arrow Component
  const CustomNextArrow = (props) => (
    <div
      {...props}
      style={{
        ...props.style,
        right: "40px",
        zIndex: 1,
        borderRadius: "50%",
        cursor: "pointer",
      }}
    >
      <img style={{ maxWidth: "45px" }} src={ArrowRight} alt="" />
    </div>
  );
  const [settings] = useState({
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 4000,
    prevArrow: <CustomPrevArrow direction="prev" />,
    nextArrow: <CustomNextArrow direction="next" />,
  });

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [TwoBHK, ThreeBHK];
  const text = [
    "2BHK - 1500   sqft",
    "3BHK - 1655   sqft",
  ];

  const properties = [
    {
      type: '2BHK',
      images: [
        {
          image: TwoBHKImg1,
          sqft: "2BHK - 1258   sqft",
        },
        {
          image: TwoBHKImg2,
          sqft: "2BHK - 1134   sqft",
        },
        {
          image: TwoBHKImg3,
          sqft: "2BHK - 1182   sqft",
        }
      ],
    },
    {
      type: '3BHK',
      images: [
        {
          image: ThreeBHKImg1,
          sqft: "3BHK - 1531   sqft",
        },
        {
          image: ThreeBHKImg2,
          sqft: "3BHK - 1549   sqft",
        },
        {
          image: ThreeBHKImg3,
          sqft: "3BHK - 1619   sqft",
        },
        {
          image: ThreeBHKImg4,
          sqft: "3BHK - 1635   sqft",
        },
        {
          image: ThreeBHKImg5,
          sqft: "3BHK - 1655   sqft",
        },
        {
          image: ThreeBHKImg6,
          sqft: "3BHK - 1742   sqft",
        }
      ],
    }
  ];
  console.log(properties[0])
  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const [selectedBhk, setSelectedBhk] = useState(null);

  const show2Bhk = () => {
    setCurrentImageIndex(0);
  };

  const show3Bhk = () => {
    setCurrentImageIndex(1);
  };

  const handleClick = () => {
    setModalShow(true);
  };
  return (
    <Container fluid style={{ height: "100%" }} id="location" className="container">
      <Row>
        <Col md={6} style={{ paddingLeft: "unset" }}>
          {/* Content for the left half */}
          <div className="border border-3 border-white d-none d-md-block" >
            {/* <img src={values} alt="3bhk" style={{ height: "600px" }} /> */}
            {selectedBhk === 2 && (
              <img src={BHK} alt="2bhk" style={{ height: '600px' }} />
            )}
            {selectedBhk === 3 && (
              <img src={ThreeBHK} alt="3bhk" style={{ height: '600px' }} />
            )}

          </div>
          {/* Content for the left half */}

          <div class="tab-content d-none d-md-block" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <img src={BHK} alt="2bhk" style={{ height: '600px' }} />
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              {/* <img src={Site2BHK} alt="2bhk" style={{ height: '600px' }} /> */}
              <img src={BHK} alt="2bhk" style={{ height: '600px' }} />

            </div>
          </div>
          <section className="d-flex align-items-center pt-4 pb-3 pb-md-0 justify-content-center">
            <h5 className="m-0 pe-3">Layout</h5>
            <ul class="nav nav-tabs border-0" id="myTab" role="tablist">
              <li class="nav-item">
                <a className={`border btn-border-radius btn-outline-gradient active px-3 py-2 me-2 `} onClick={show2Bhk} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                  2BHK
                </a>
              </li>
              <li class="nav-item">
                <a className={`border btn-border-radius btn-outline-gradient px-3 py-2 me-2 `} onClick={show3Bhk} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                  3BHK
                </a>
              </li>

            </ul>
          </section>


          {/* <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <img src={BHK} alt="2bhk" style={{ height: '600px' }} />
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
          </div>

          <div className="d-flex align-items-center pt-4">
            <h4 className="mb-0">Layout</h4>
            <div className="ps-2">
              <button
                className={`border btn-border-radius px-3 py-1 me-2 ${selectedBhk === 2 ? 'btn-gradient' : 'btn-outline-gradient'
                  }`} >
                2BHK
              </button>
              <button
                className={`border btn-border-radius px-3 py-1 ${selectedBhk === 3 ? 'btn-gradient' : 'btn-gradient'
                  }`}>
                3BHK
              </button>
            </div>
          </div> */}
        </Col>
        <Col md={6} className="bg-light pb-2">
          {/* Content for the right half */}
          <div className="d-flex flex-column h-100 overflow-hidden bg-light">



            <Slider {...settings}>

              {properties[currentImageIndex].images.map((image, imgIndex) => (
                <div key={imgIndex} className="px-5 pt-0 pt-md-4">
                  <h4 className="text-center fw-400 map-title">{image.sqft}</h4>
                  <img src={image.image} alt={`${properties[currentImageIndex].type} ${imgIndex + 1}`} className="mx-auto" style={{ width: "80%" }} />
                </div>
              ))}
            </Slider>

            {/* Right arrow */}
            {/* <img src={ArrowRight} alt="Right Arrow" onClick={handleNext} className="cursor-pointer icon-size-sm" /> */}
            {/* </Container> */}
            <div className="mt-auto">
              <div className="text-span-bg d-flex justify-content-center align-items-center">
                {/* <span>Bagaluru Lake - 3.2Km</span> */}
                {/* <span>{texts[currentImageIndex]}</span> */}
              </div>

              <div className="d-flex justify-content-center">
                <button
                  className="btn-outline-dark px-5 mb-4"
                  style={{ marginTop: "20px", }} onClick={handleClick}>
                  Know More
                </button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SitePlan;
