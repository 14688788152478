import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import "./style.css";


const accordionData = [
  {
    question: "What does “Designed for Life, Built for You” mean?",
    answer: "This tagline reflects the thoughtfulness and care put into Nirvana. Every home is meticulously designed to enhance day-to-day living with a focus on comfort, convenience, and quality. It also highlights that each unit is built with the unique preferences and lifestyles of future residents in mind."
  },
  {
    question: "What does Nirvana offer?",
    answer: "Nirvana is a premium residential development offering luxury homes in a serene and well-connected location. It features state-of-the-art amenities, modern design, and a lifestyle focused on comfort and convenience."
  },
  {
    question: "Where is Nirvana located?",
    answer: "Nirvana is located in the rapidly growing residential locale of Kannamangala, Whitefield, providing excellent connectivity to major business hubs, educational institutions, healthcare centers, and entertainment zones."
  },
  {
    question: "What are the different types of homes available?",
    answer: "The project offers 2 and 3 BHK homes with spacious layouts and high-end finishes designed to cater to the diverse needs of homebuyers."
  },
  {
    question: "What are the key amenities provided in the Nirvana Project?",
    answer: "Residents can enjoy a wide range of amenities, including a swimming pool, gym and fitness center, kids’ play area, landscaped garden, badminton court, cricket net, and a multi-purpose hall. Residents also benefit from 24/7 security, along with recreational facilities such as air hockey, table tennis, pool table, and foosball. Additionally, EV charging points are provided for sustainable living."
  },
  {
    question: "What is the possession timeline for the Nirvana Project?",
    answer: "The project is scheduled for completion and possession by July 2025 with a grace period of three months."
  },
  {
    question: "Is the project approved by regulatory authorities?",
    answer: "Yes, the Nirvana Project is fully approved by the necessary regulatory bodies, including RERA and BDA, ensuring compliance with all legal and construction standards. It is an A Katha project."
  },
  {
    question: "What financing options are available?",
    answer: "We have partnered with leading banks and financial institutions including SBI, HDFC, and IDBI, to offer home loan options. You can also avail of flexible payment plans like the Construction Linked Payment (CLP) plan for ease of payment."
  },
  {
    question: "Who is the developer of Nirvana?",
    answer: "Nirvana is developed by Virtue Infracon, a reputed developer known for delivering high-quality residential projects with a focus on timely delivery and customer satisfaction."
  },
  {
    question: "What makes Nirvana unique?",
    answer: "Nirvana stands out due to its prime location, luxurious living experience, modern and contemporary design, top-notch amenities, and enhanced security and privacy for residents."
  },
  {
    question: "How can I schedule a site visit or get more information?",
    answer: "You can schedule a site visit by contacting us at the details mentioned below or by filling out the inquiry form on our website. Our team will assist you with further information and guide you through the project."
  },
  {
    question: "Are there any ongoing offers or discounts?",
    answer: "We currently have special launch offers for early buyers. Please get in touch with our sales team for details on current promotions and offers."
  }
];

const Faqs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="faqs"
      className="container container-md-fluid map-div-custom d-flex align-items-center justify-content-center flex-column section-14 pb-2 pb-md-5"
    >
      <span className="sec_title_about text-center text-uppercase pb-3 pb-md-0">
        FAQs
      </span>

      {/* <span className="d-none d-md-inline sec_subtitle text-center">
        Having queries? Let’s see we can resolve or not!
      </span> */}
      <div className="col-11 col-sm-9 col-md-7 accordion-bg">
        <Accordion defaultActiveKey="0">
          {accordionData.map((item, index) => (
            <Accordion.Item key={index} eventKey={index}>
              <Accordion.Header className="acc-header-custom position-relative bg-light mb-2">
                <div className="d-flex align-items-center acc-header-custom">
                  <h4 className="mb-0">{item.id}</h4>
                  <h6 className="fq_font-2 mb-0 px-3">{item.question}</h6>
                </div>
              </Accordion.Header>
              <Accordion.Body
                className="text-start accordion-body"
                style={{ borderBottom: "1px solid #ccc" }}
              >
                {item.answer}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faqs;
