import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import axios from "axios";
import Logo from "../../images/brand-logo-horizontal.svg";
// import Logo from "../assets/Logo/5.jpeg";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import "./style.css";

function MyVerticallyCenteredModal(props) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    configuration: "",
  });
  const [value, setValue] = useState();
  console.log(form);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (value.length < 9) {
      toast.error("Please Enter a valid Phone Number");
      return;
    }
    setLoading(true);
    const data = {
      email: form.email,
      name: form.name,
      phone: value,
      configuration: form.configuration,
    };
    const name = form.name.split(" ");
    const data2 = [
      {
        Attribute: "FirstName",
        Value: name[0],
        Fields: null,
      },
      {
        Attribute: "LastName",
        Value: name[1],
        Fields: null,
      },
      {
        Attribute: "EmailAddress",
        Value: form.email,
        Fields: null,
      },
      {
        Attribute: "Phone",
        Value: value,
        Fields: null,
      },
      {
        Attribute: "mx_Projects_Name",
        Value: "Verzure",
      },
      {
        Attribute: "Source",
        Value: "Google Ads",
      },
    ];
    try {
      const response = await axios.post(
        "https://api.aspireprop.com/cn/addVenzureEnquiry",
        data
      );
      setForm({
        email: "",
        name: "",
        phone: "",
        configuration: "",
      });
      setValue();
      navigate("/thank-you");
    } catch (err) {
      alert("Please Try Again");
    }
    setLoading(false);
  };
  console.log(value);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        className="modal-header bg-light"
        closeButton
      >
        <Modal.Title
          className="col-10"
          id="contained-modal-title-vcenter flex-grow-1 modal-title m-0"
        >
          <img src={Logo} className="logo-img-modal" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Form className="mx-3 modal-form" onSubmit={handleFormSubmit}>
          <div className="row ">
            <div className="col-12 my-3">
              <input
                className="input w-100 px-2"
                type="text"
                required
                placeholder="Name"
                value={form.name}
                onChange={handleFormChange}
                name="name"
              />
            </div>
            <div className="col-12">
              <input
                className="input w-100 px-2"
                type="email"
                required
                placeholder="Email"
                value={form.email}
                onChange={handleFormChange}
                name="email"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <PhoneInput
                required
                className="input w-100 phone-input"
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                country={"in"}
                value={value}
                onChange={(e) => setValue(e)}
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              <select
                className="input w-100 px-2"
                type="text"
                required
                placeholder="Enter a message.."
                onChange={handleFormChange}
                name="configuration"
              >
                <option selected disabled value="">
                  Select Configuration
                </option>
                <option value="3 BHK">2 BHK</option>
                <option value="4 BHK">3 BHK</option>
              </select>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-center" type="submit">
            <button disabled={loading} className="form_submit_btn btn-gradient">
              {loading ? "Loading..." : "Submit"}
            </button>
            
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const FormModal = ({ modalShow, setModalShow }) => {
  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        backdrop="static"
        keyboard={false}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default FormModal;
