import React from 'react';


const Videocomponent = ({ videoId }) => {
    console.log("the comoponent is loading")
  return (
    
    <div className="youtube-video-container" style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%' }}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`} // Optional: autoplay and mute for background video
        title="YouTube Video"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
      ></iframe>
    </div>
  );
};

export default Videocomponent;
