import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import Logo from "../images/brand-logo-horizontal.svg";
import OutlineMenu from "../images/responsive-menu-logo.png";

const Header = ({ setModalShow }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setModalShow(true);
  }
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);
  // Header items array
  const headerItems = [
    {
      name: "Overview",
      href: "overview"
    },
    {
      name: "Gallery",
      href: "gallery"
    },
    {
      name: "Location",
      href: "location"
    },
    {
      name: "Configuration",
      href: "configuration"
    },
    {
      name: "Amenities",
      href: "amenities"
    },
    {
      name: "Key Features",
      href: "keyFeatures"
    },
    {
      name: "FAQ's",
      href: "faqs"
    }
  ];
  
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    if (scrollTop > lastScrollTop) {
      // Scrolling down
      setIsVisible(false);
    } else {
      // Scrolling up
      setIsVisible(true);
    }

    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile or negative scrolling
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);
  return (
    <header className={`header ${isVisible ? 'visible' : 'hidden'}`}>
      <div>
        <img src={Logo} alt="Brand Logo" className="brand-logo" />
      </div>
      {/* For larger devices */}
      <div className="d-none d-xl-flex flex-grow-1 justify-content-end">
        <ul className="header-routes">
          {headerItems.map((link, index) => (
            <li key={index}>
              <a href={`#${link.href}`}>{link.name}</a>
            </li>
          ))}
          <li>
            <button className="btn-border-radius btn-gradient px-3 py-2 ms-0 ms-md-4" type="button" onClick={handleClick}>
             Download Brochure
            </button>
          </li>
        </ul>
      </div>

      <div className="d-flex justify-content-end flex-grow-1 pe-3 d-xl-none">
        <div onClick={() => toggleShow()}>
          <div class="nav-icon d-flex flex-column align-items-end">
            <div></div>
            <div className="line-2"></div>
          </div>
          {/* <img src={OutlineMenu} alt="outlineMenu" /> */}
        </div>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop={true}
        scroll={true}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul
            onClick={() => {
              setShow(false);
            }}
            className="header-routes-canvas d-flex align-items-center flex-column"
          >
            <a href="#overview">Overview</a>
            <a href="#configuration">Configuration</a>
            <a href="#amenities">Amenities</a>
            <a href="#keyFeatures">Gallery</a>
            <a href="#gallery">USP's</a>
            <a href="#location">Location</a>
            <a href="#faqs">FAQ's</a>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;
