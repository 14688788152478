import React, { useState, useEffect, useCallback } from "react";
import {
  CarParking,
  Club,
  Gym,
  Indoor,
  Swimming,
  Badminton,
  Rain,
  Snooker,
  PowerBackup,
  clubHouse
} from "../../images/amenities/index.js";
import "./style.css";

const cardData = [
  {
    title: "Swimming Pool",
    url: Swimming,
  },
  {
    title: "Gymnasium",
    url: Gym,
  },
  {
    title: "Club House",
    url: Club,
  },
  {
    title: "Badminton Court",
    url: Badminton,
  },
  {
    title: "Landscape Garden",
    url: Rain,
  },
  {
    title: "Party Hall",
    url: Indoor,
  },
  {
    title: "Play Area",
    url: clubHouse,
  },
  {
    title: "Car Parking",
    url: CarParking,
  },
  {
    title: "Snooker",
    url: Snooker,
  },
  {
    title: "EV Charging",
    url: PowerBackup,
  },
];

const Amenities = ({ setModalShow }) => {
  const [visibleCards, setVisibleCards] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  useEffect(() => {
    // if (items == "") addItem();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const initialCount = isMobile ? 6 : 10;
    setVisibleCards(cardData.slice(0, initialCount));
  }, [isMobile]);

  const handleShowMore = () => {
    setVisibleCards(cardData);
    setShowMore(true);
  };


  return (
    <div
    id="amenities"
    className="justify-content-center bg-light amenities-wrapper pb-5"
    style={{ paddingTop: "50px" }}
  >
    <h4 className="amenities_title text-center text-dark py-2 pb-5">Amenities Designed <br/> for a Modern Lifestyle</h4>
    <div className="grid-container">
      {visibleCards.map(item => (
        <div key={item.title}>
          <div className="card aminities_card border-0 shadow-sm mx-auto mb-3 mb-mb-0">
            <img className="col-4" src={item.url} alt={item.title} />
            <h5 className="card_h5 text-gray-600 fw-400 text-center pt-4">{item.title}</h5>
          </div>
        </div>
      ))}
    </div>
    {/* && !showMore */}
    {isMobile  && (
      <div className="col text-center d-block d-md-none">
        <button className="btn-gradient px-5 my-5" onClick={handleShowMore}>
          Show More
        </button>
      </div>
    )}
  </div>
  );
};

export default Amenities;
