import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Image1 from "../../images/secondSliderImg.png";
import Image2 from "../../images/banner-1.png";
import Image3 from "../../images/banner-2.png";
import PreArrrow from "../../images/Banner/prev-arrow.png";
import NextArrrow from "../../images/Banner/next-arrow.png";
import Image4 from "../../imgs/Web/Image4.png";
import Image5 from "../../imgs/Mobile/Image4-banner-mobile.png";
import "./style.css";

// const CustomPrevArrow = (props) => (
//   <div
//     {...props}
//     style={{
//       ...props.style,
//       left: "30px",
//       zIndex: 1,
//       borderRadius: "50%",
//       cursor: "pointer",
//     }}
//   >
//     <img style={{ maxWidth: "45px" }} src={PreArrrow} alt="" />
//   </div>
// );

// // Custom Arrow Component
// const CustomNextArrow = (props) => (
//   <div
//     {...props}
//     style={{
//       ...props.style,
//       right: "-30px",
//       zIndex: 1,
//       borderRadius: "50%",
//       cursor: "pointer",
//     }}
//   >
//     <img style={{ maxWidth: "45px" }} src={NextArrrow} alt="" />
//   </div>
// );

// const SliderSection = ({ currentSlide, setCurrentSlide, setModalShow }) => {
//   const [settings] = useState({
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: false,
//     arrows: true,
//     autoplaySpeed: 4000,
//     prevArrow: <CustomPrevArrow direction="prev" />,
//     nextArrow: <CustomNextArrow direction="next" />,
//   });
//   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };

//     window.addEventListener("resize", handleResize);

//     // Clean up the event listener
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const handleAfterChange = (current) => {
//     setCurrentSlide(current);
//   };

//   const handleEnquireNow = () => {
//     setModalShow(true);
//   };


//   const gallery = [Image1, Image2, Image3];
//   return (
//     <>
//     <h1 style={{
//   display: 'flex', 
//   justifyContent: 'center',  // Horizontally center
//   alignItems: 'center',      // Vertically center
//   flexDirection: 'column',   // Ensure text is stacked correctly
//   textAlign: 'center',       // Center the text itself
//   fontSize: 'calc(2em + 2vw)', // Bigger and responsive font size
//   // height: '100vh',           // Full viewport height to center vertically
//   margin: 0                  // Remove default margin
// }}>
//   Discover the Elegance <br/> of Nirvana
// </h1>

//       <div className="home-sec-1 pt-0 pt-md-4 container px-0 px-md-2" id="keyFeatures">
//         <Slider {...settings} afterChange={handleAfterChange}>


//           {gallery.map((image, index) => (
//             <>
//               <div className="home-img" key={index} style={{ position: 'relative', overflow: 'hidden' }}>
//                 {/* Blurred background image */}
//                 <div
//                   style={{
//                     position: 'absolute',
//                     top: 0,
//                     left: 0,
//                     width: '100%',
//                     height: '100%',
//                     backgroundImage: `url(${image})`, // Set background image
//                     backgroundSize: 'cover',
//                     backgroundPosition: 'center',
//                     filter: 'blur(10px)', // Apply the blur effect
//                     opacity: 0.8,
//                     zIndex: 0 // Ensure the background is behind the main image
//                   }}
//                 >
//                   {/* <div
//       style={{
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         backgroundColor: 'rgba(255, 255, 255, 0.5)', // White color with 50% transparency
//         zIndex: 1 // Ensure it sits on top of the blurred background
//       }}
//     ></div> */}
//                 </div>

//                 <div className="position-relative w-100">
//                   <div className="home-img-mobile-wrap position-absolute text-center w-100">
//                     <div className={windowWidth > 768 ? "slider-section-2-button-wrapper d-none" : "slider-section-1-mobile-button-wrapper d-none"}>
//                     </div>
//                   </div>
//                   <img src={image} alt="poster" className={windowWidth > 768 ? "home-window-img" : "home-slider-resp"} />
//                 </div>

//               </div>

//             </>
//           ))

//           }



//         </Slider>
//       </div>
//     </>
//   );
// };

// export default SliderSection;






const PhotoGrid = () => {
  // Example photo array
  const importAll = (r) => r.keys().map(r);
const photos = importAll(require.context('../../images/grid', false, /\.(png|jpe?g|svg)$/));



  // State to control whether "Show more" has been clicked
  const [showMore, setShowMore] = useState(false);

  // Only show 9 photos initially (3 rows, 3 columns)
  const visiblePhotos = showMore ? photos : photos.slice(0, 9);

  return (
    <div>
      <h1 style={{
  display: 'flex', 
  justifyContent: 'center',  // Horizontally center
  alignItems: 'center',      // Vertically center
  flexDirection: 'column',   // Ensure text is stacked correctly
  textAlign: 'center',       // Center the text itself
  fontSize: 'calc(2em + 2vw)', // Bigger and responsive font size
  // height: '100vh',           // Full viewport height to center vertically
  margin: 0                  // Remove default margin
}}>
  Discover the Elegance <br/> of Nirvana
</h1>
      {/* Photo Grid */}
      <div className="home-sec-1 pt-0 pt-md-4 container px-0 px-md-2 photo-grid" id="keyFeatures">
        {visiblePhotos.map((photo, index) => (
          <div key={index} className="photo-item">
            <img src={photo} alt={`Photo ${index + 1}`} />
          </div>
        ))}
      </div>

      <div className="d-flex justify-content-center">
        <button className="btn-gradient px-5 mx-md-auto mx-lg-0 mt-3" onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Show Less' : 'Show More'}
        </button>
        </div>
        <br/>
        <br />
    </div>
  );
};

export default PhotoGrid;
