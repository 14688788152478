import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ConfirmationPage from "./components/ConfirmationPage";
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path='/thank-you' element={<ConfirmationPage />} />
    </Routes>
  );
}

export default App;
