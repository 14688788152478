import React, { useState } from "react";
import Header from "./Header";
import SliderSection1 from "./SliderSection/SliderSection1";
import WelcomWarriorSection from "./WelcomWarriorSection";
import HeartOfNorthSection from "./HeartOfNorthSection/HeartOfNorthSection";
import SocialMediaContact from "./SocialMediaContact/SocialMediaContact";
import Companies from "./Companies";
import Space from "./Space/Space";
import PerfectlyDesignedHomes from "./PerfectlyDesignedHomes/PerfectlyDesignedHomes";
import Amenities from "./Amenities/Amenities";
import ElevateYourLife from "./ElevateYourLife";
import About from "./About";
import Faqs from "./FAQ/Faqs";
import Footer from "./Footer/footer";
import UrbanLuxuryLifestyle from "./UrbanLuxuryLifestyle/UrbanLuxuryLifestyle";
import FormModal from "./Form/Form";
// import MapSection from "./MapSection/MapSection";
import GeometricImageScroll from "./GeometricImageScroll/GeometricImageScroll";
import Location from "./MapSection/Location";
import MapSection from "../components/MapSection";
import SitePlan from "../components/SitePlan";
import Youtube from "../components/Youtube";
import Videocomponent from "./Videocomponent.jsx"
import Virtue from "./Virtue.jsx"
const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <Header setModalShow={setModalShow} />

      <SocialMediaContact />
      {/* {currentSlide === 1 && <SocialMediaContact />} */}
      <WelcomWarriorSection setModalShow={setModalShow} />
      <div>

      <Videocomponent videoId="G3XyJ-GO4TE" />
    </div>
     <SliderSection1  /> 
      {/* the above for photos  */}
      {/* <Companies /> */}
      {/* <HeartOfNorthSection setModalShow={setModalShow} /> */}
      <MapSection setModalShow={setModalShow} />
      {/* <GeometricImageScroll /> */}
      <Space setModalShow={setModalShow} />
      <SitePlan setModalShow={setModalShow} />
      {/* <PerfectlyDesignedHomes setModalShow={setModalShow} /> */}
      {/* <UrbanLuxuryLifestyle setModalShow={setModalShow} /> */}
      <Amenities setModalShow={setModalShow} />
      <ElevateYourLife />
      {/* <Youtube /> */}
      {/* <Location /> */}
      {/* <About /> */}
      {/* ---- Skip the testimonials section for now --- */}
      <FormModal modalShow={modalShow} setModalShow={setModalShow} />
      <br/>
      <br />
      <Virtue />
      <Faqs />
      <Footer />
    </div>
  );
};

export default Home;
